import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"

export default function ThanksPage() {
  return (
    <>
      <SEO title="Thanks - Form Submitted" />
      <Container className="d-flex-column justify-content-center align-items-center">
        <Card className="w-100" style={{ maxWidth: `50rem`, margin: `3rem auto` }}>
          <Card.Header className="m-3 p-3 text-center" style={{ color: `var(--gold)`, textShadow: `0.25rem 0.25rem #412456`}}>
            <h3>Form Submitted Successfully</h3>
          </Card.Header>
          <Card.Body className="m-3 thks" >
            <p>Thanks for taking the time to write to Ask The Tarot</p>
            <br />
            <p>Someone will respond to your query within the next 48 hours</p>
            
           
          </Card.Body>

          <Card.Footer className="m-0 p-0">
            <Button className="w-100">
              <Link to="/">Continue Browsing</Link>
            </Button>
          </Card.Footer>
        </Card>
      </Container>
    </>
  )
}
